import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'

import Hero from '../../../components/dai/Hero'
import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import Quote from '../../../components/dai/Quote'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'
import { Button } from '../../../components/dai/Button'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'
import TextLink from '../../../components/common/TextLink'

import Speaker from '../../../images/dai/icons/assets/speaker.svg'
import LaptopError from '../../../images/dai/icons/assets/laptop-error.svg'
import Hide from '../../../images/dai/icons/assets/hide.svg'
import Form from '../../../images/dai/icons/assets/form.svg'

const MaxImage = styled.div`
  max-width: 200px;
`

export default function Retail() {
  return (
    <LayoutDAI
      freeAudit
      showIndustryReports
      pagePath={{
        parent: 'industry-reports',
        child: 'retail',
        freeAuditHeading: 'Find out how your site compares to other retail sites.',
      }}
    >
      <SEO
        title="Retail Accessibility | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/industry-reports/retail/"
        desc="Our expert review of the top retail sites found 17 accessibility barriers across the login, product, and checkout pages."
        bannerUrl="https://images.prismic.io/audioeye-web/e2365eb0-6059-4ab7-b3c0-2c24157cb2d3_retail.jpg?auto=compress,format"
        imageAlt="Stylized billboard for the Retail industry with an image that reads “shirt.jpg” and multiple notifications that it was added to cart."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/industry-reports/retail/`}
      />
      <Hero
        image={
          <StaticImage
            src="../../../images/dai/hero/retail.png"
            alt="Stylized billboard for the Retail industry with an image that reads “shirt.jpg” and multiple notifications that it was added to cart."
          />
        }
        backgroundColor="orange200"
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          RETAIL
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Retail">
          Accessibility barriers are driving customers away.
        </Heading1>
        <Space height={32} />
        <Text>
          Along with friends and family, people with disabilities control almost $13 trillion in disposable income
          globally. Yet despite their buying power,{' '}
          <BoldText>
            54% of assistive technology users believe online retailers don’t care about earning their business.
          </BoldText>
        </Text>
        <Space height={32} />
        <Text>
          When we asked members of the disability community what it was like to shop online, many of them noted that
          accessibility barriers are a persistent challenge — especially when it came to essential activities like
          viewing product photos, purchasing items, or managing their account information.
        </Text>
      </Hero>
      <Section paddingTop="xl" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“It’s genuinely disheartening. And it’s way too common. I could not tell you how many times I’ve been unable to access my cart, fill out my shipping information, or solve the CAPTCHA.”"
          author="Chris Preiman"
          title="AudioEye A11iance Member & Internet Security Professional"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          EXPERT AUDITS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Breaking down the most significant barriers on the top retail sites.
        </Heading2>
        <Space height={32} />
        <Text>
          Our expert review of the top retail sites revealed many of the same issues as our automated scan — plus a
          number of significant accessibility barriers that made it difficult for people to navigate between pages, add
          items to their cart, or be alerted to missing information on checkout forms.
        </Text>
        <Space height={32} />
        <Text>
          Across the four sites audited, our testers found another{' '}
          <BoldText>17 accessibility barriers across the login, product, and checkout pages</BoldText> — including four
          consistent barriers that impacted their ability to make purchases.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns orderedList>
          <Column size={12} listItem>
            <IconCard noCard>
              <Speaker />
              <Space height={16} />
              <Text heading3 className="asH4">
                1. No audible announcement when forms were missing required information.
              </Text>
              <Space height={16} />
              <Text>
                Our experts found multiple forms on product and checkout pages that did not audibly announce when
                required information was missing, which can leave non-sighted customers unaware that their form was not
                submitted.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/error-identification.html"
                  target="_blank"
                  rel="noreferrer"
                  text="3.3.1: Error Identification;"
                  anchorClassName="unstyled-link dai-link"
                />
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                  target="_blank"
                  rel="noreferrer"
                  text="3.3.2: Labels or Instructions"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <LaptopError />
              <Space height={16} />
              <Text heading3 className="asH4">
                2. No alert when items were added to a user’s cart.
              </Text>
              <Space height={16} />
              <Text>
                Our experts encountered "Add to Cart" buttons that did not trigger an audible announcement when clicked,
                which can leave non-sighted customers unsure if an item has been successfully added — or if they’ve
                accidentally added multiples of an item.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/error-identification.html"
                  target="_blank"
                  rel="noreferrer"
                  text="3.3.1: Error Identification;"
                  anchorClassName="unstyled-link dai-link"
                />
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                  target="_blank"
                  rel="noreferrer"
                  text="3.3.2: Labels or Instructions"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <Hide />
              <Space height={16} />
              <Text heading3 className="asH4">
                3. Buttons were not clearly labeled for people with visual impairments.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted dozens of buttons that were not clearly labeled, meaning they had to click each one to
                figure out where it would take them. This can make it harder for non-sighted users to navigate a site,
                or take their desired action on a page.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
                  target="_blank"
                  rel="noreferrer"
                  text="4.1.2: Name, Role, Value"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <Form />
              <Space height={16} />
              <Text heading3 className="asH4">
                4. Error messages on forms lacked contextual detail.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted several instances where a form was missing required information, but there was no
                audible announcement of what information to enter. This can be confusing for non-sighted customers and
                people with cognitive impairments.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/error-identification.html"
                  target="_blank"
                  rel="noreferrer"
                  text="3.3.1: Error Identification;"
                  anchorClassName="unstyled-link dai-link"
                />
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                  target="_blank"
                  rel="noreferrer"
                  text="3.3.2: Labels or Instructions"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“It can be hard because there’s no public transportation where I live. So if I can’t buy what I need online, I have to find a sighted person to drive me to the store and hope they can find what I’m looking for.”"
          author="Wren Higginbottom"
          title="AudioEye A11iance Member"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          AUTOMATED SCAN RESULTS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Most common issues, retail sites.
        </Heading2>
        <Space height={32} />
        <Text>
          Our automated scan revealed that retail sites have an above-average rate of multiple accessibility issues,
          including some (like image accessibility) that are a key part of shopping online.
        </Text>
        <Space height={32} />
        <Text>
          Studies show that 75% of online shoppers rely on product photos to help them make a purchase decision,{' '}
          <BoldText>
            yet retail sites had one of the highest rates of inaccessible images across all industries.
          </BoldText>
        </Text>
        <Space height={32} />
        <Text>
          Retailers also struggle with things like button and link accessibility, which can make it difficult for
          customers to navigate between pages, add items to cart, and more.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/triangle-sign-72.png"
                alt="Pie chart representing 72%. The pie chart is in the shape of a yield traffic sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              72% of retail pages had at least one image with missing or inadequate alt text.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive alt text on product photos and other graphics, people with visual and cognitive
              disabilities can have a hard time understanding what an image is supposed to illustrate.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                target="_blank"
                rel="noreferrer"
                text="1.1.1: Non-Text Content"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/triangle-sign-47.png"
                alt="Pie chart representing 47%. The pie chart is in the shape of a yield traffic sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              47% of retail pages with a form had at least one field that was not labeled.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive field labels, it can be difficult for people with visual and cognitive impairments to
              add payment methods, enter shipping addresses, and more.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                target="_blank"
                rel="noreferrer"
                text="3.3.2: Labels or Instructions"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/triangle-sign-68.png"
                alt="Pie chart representing 68%. The pie chart is in the shape of a yield traffic sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              68% of retail pages had at least one link that did not clearly state where it would take users.
            </Text>
            <Space height={24} />
            <Text>
              For people with visual and cognitive disabilities, clear, descriptive link text is a critical part of
              being able to navigate websites.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.4: Link Purpose (In Context)"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" paddingBottom="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/triangle-sign-44.png"
                alt="Pie chart representing 44%. The pie chart is in the shape of a yield traffic sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              44% of retail pages had at least one button that did not clearly state where it would take users.
            </Text>
            <Space height={24} />
            <Text>
              For people with visual and cognitive disabilities, clear, descriptive buttons is a critical part of being
              able to navigate websites.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.4: Link Purpose (In Context)"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="xl" paddingBottom="xl" constraint="lg" backgroundColor="orange300">
        <Columns>
          <Column size={16} tabletSize={14}>
            <Text heading3 className="asH4">
              Get expert insight into the accessibility of your product pages and checkout flows.
            </Text>
            <Space height={32} />
            <Text>
              Uncover accessibility barriers that are making it hard for people with disabilities to compare products or
              make a purchase with an expert audit of your site.
            </Text>
          </Column>
          <Column size={8} tabletSize={10} justifyCenter alignItemsEnd>
            <Button
              text="Schedule Expert Audit"
              to="/digital-accessibility-index/industry-reports/retail/#expert-audit"
              size="small"
              tag="DAI Page Content"
            />
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/industry-reports/"
        previousPageText="Industry Reports"
        nextPageLink="/digital-accessibility-index/industry-reports/finance/"
        nextPageText="Financial Services"
      />
    </LayoutDAI>
  )
}
